<template>
	<div class="study">
		<studyTitle
				:loadingFace="loadingFace"
				:buttonType="buttonType"
				@handleFace="handleFace"
				@imgChange="imgChange"
			/>
		<iframe id="fullscreen-iframe" :src="this.loginSrc"></iframe>
		<input v-show="false" ref="imgChange" @change="imgChange($event)" type="file" accept="image/*" capture="user">
	</div>
</template>

<script>
import {Dialog} from 'vant';
import studyTitle from './components/title'
import {getPlanInfo,} from "../../api/user"

export default {
	name: 'study',
	data: () => ({
		buttonType:"signOut",
		loadingFace: false,
		loginSrc:"",
		faceRecognitionType:0,
		showLoading: false,
		submitStudyTimer: null, 	// 定时器
	}),
	components: {studyTitle},
	created() {
		this.loginSrc = window.location.origin + "/#/viplogin?appNo=APP02&state=single&idcard="+this.$route.query.idCard
		/** 清除签到人脸 **/
		this.$store.commit('cache/set_face', {key: 'signInPhoto', value: ''})
		this.getPlanInfo()
	},
	computed: {
		userid() {
			return this.$store.state.user.userid
		},
		isUseJSPlayPhoto() {
			if (this.$store.state.cache.screen.wx) return true
			if (this.$route.query.state === 'js') return false
			if (this.$store.state.cache.single && this.$store.state.cache.single.state === 'single') return true
			return true
		},
		appno() {
			return this.$store.state.app.appno
		}
	},

	mounted() {
		this.makeIframeFullScreen();
		const _this = this
		/** 绑定拍照 JS 回调事件 **/
		window['receivePhotoResult'] = (base64) => {
			this.receivePhotoResult(base64);
		}
		if (this.$store.state.cache.screen.wx) {
			this.uploading = true
			this.$api.wxConfig().then(res => {
				if (res.code === 0) {
					const data = res.data
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: data.appId, // 必填，公众号的唯一标识
						timestamp: data.timestamp, // 必填，生成签名的时间戳
						nonceStr: data.nonceStr, // 必填，生成签名的随机串
						signature: data.signature,// 必填，签名
						jsApiList: ['chooseImage', 'uploadImage'] // 必填，需要使用的JS接口列表
					})
				}
			})
			wx.ready(() => {
				_this.uploading = false
			})
			wx.error(err => {
				_this.uploading = false
				console.log('微信config出错 ===', err)
				this.$utils.dialog.alert(`微信config失败：${err.errMsg}`)
			})
		}
	},
	methods: {
		navBack() {
				window.location.href = sessionStorage.getItem("loginSteam")
				window.location.reload()
		},
		getPlanInfo() {
			getPlanInfo(this.userid).then(res => {
				this.faceRecognitionType = res.faceRecognitionType
				if(this.$route.query.continuing == 1 && res.faceRecognitionType != 0){
					this.middleFace = true;
					this.buttonType = "reCheck",
					this.$utils.dialog.alert('需要验证人脸，请进行人脸识别', () => {
						// 此处 调用APP 摄像头方法
						if (this.isUseJSPlayPhoto) {
							this.handleFace()
						} else {
							this.$refs.imgChange.click()
						}
					});
				}else{
					this.timingSubmitStudyThrittle(res.faceAcquisitionInterval * 1000)
				}
			})
		},
		/**
		 * @param { number | string } wait： 执行时间 (number) 是否停止定时器 (string: clear)
		 */
		timingSubmitStudyThrittle(wait) {
			setTimeout(() => {
				this.middleFace = true;
				this.buttonType = "reCheck",
				this.$utils.dialog.alert('需要验证人脸，请进行人脸识别', () => {
						// 此处 调用APP 摄像头方法
						if (this.isUseJSPlayPhoto) {
							this.handleFace()
						} else {
							this.$refs.imgChange.click()
						}
				});
			}, wait)
		},
		makeIframeFullScreen() {
				let element = document.getElementById("fullscreen-iframe");
				if (element) {
				element.style.zIndex = "1";
				element.style.position = "absolute";
				element.style.left = "0";
				element.style.right = "0";
				element.style.top = "0";
				element.style.bottom = "0";
				element.style.width = "100%";
				element.style.height = "100%";
				element.style.border = "none";
				}
		},
		imgChange(event) {
			if (this.loadingFace) return
			let file = event.target.files[0];
			let reader = new FileReader();
			const _this = this;
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				_this.receivePhotoResult(event.target.result)
			}
		},
		/** 拍照区域 **/
		handleFace() {
			if(this.faceRecognitionType == 0){
				this.signBackNoPhoto()
			}
			this.showLoading = true
			const _this = this
			if (this.$store.state.cache.screen.wx) {
				wx.chooseImage({
					count: 1, // 默认9
					sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function (res) {
						wx.uploadImage({
							localId: res.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
							isShowProgressTips: 1, // 默认为1，显示进度提示
							success: function (uploadRes) {
								_this.$api.wxCompareFace({
									mediaId: uploadRes.serverId,
									signId: _this.$route.query.signId,
									chapterId: "-1",
								}).then((res) => {
									if (res.code === 0) {
										this.showLoading = false
										if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
											_this.middleFace = false
											_this.$utils.dialog.alert('验证成功，请继续学习', () =>
											{})
											_this.buttonType = "signOut",
											console.log("比对成功后再次设置定时任务")
											_this.getPlanInfo()
										} else {
											_this.signBack({
												mediaId: uploadRes.serverId
											})
										}
									}else{
										_this.buttonType = "reCheck",
										_this.$utils.dialog.alert('比对失败，需要重新验证人脸', () => {
											// 此处 调用APP 摄像头方法
											if (_this.isUseJSPlayPhoto) {
												_this.handleFace()
											} else {
												_this.$refs.imgChange.click()
										}
									});
									}
								}).catch(() => {
										_this.buttonType = "reCheck",
										_this.$utils.dialog.alert('比对失败，需要重新验证人脸', () => {
											// 此处 调用APP 摄像头方法
											if (_this.isUseJSPlayPhoto) {
												_this.handleFace()
											} else {
												_this.$refs.imgChange.click()
										}
									});
								})
							}
						})
					}
				})
			} else {
				if (this.$store.state.cache.screen.isAndroid) {
					window.Android_JS.androidPhoto();
				}
				if (this.$store.state.cache.screen.isIos) {
					window.webkit.messageHandlers.iosPhoto.postMessage(null);
				}
			}
		},
		receivePhotoResult(base64) {
			const _this = this;
			this.loadingFace = true
			if (',102,104,105,106,168,'.indexOf(',' + _this.categoryId + ',') !== -1 && _this.provinceCode === '360000') {
				// 江西初学
				if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
					this.destroyedTimer();
					this.$api.compareFace({
						signId: _this.$route.query.signId,
						photo: base64,
						chapterId: _this.playVideo.basePkId
					}).then((res) => {
						if (res.code === 0) {
							_this.middleFace = false
							_this.$utils.dialog.alert('验证成功，请继续学习', () => {
							})
						}
						_this.loadingFace = false
					}).catch(() => {
						_this.loadingFace = false
					})
				} else {
					_this.signBack({base64})
				}
			} else {
				this.$api.compareFace({
					signId: _this.$route.query.signId,
					photo: base64,
					chapterId: _this.playVideo.basePkId
				}).then((res) => {
					if (res.code === 0) {
						if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
							_this.middleFace = false
							_this.$utils.dialog.alert('验证成功，请继续学习', () => {
							})
						} else {
							_this.signBack({base64})
						}
					}
					_this.loadingFace = false
				}).catch(() => {
					_this.loadingFace = false
				})
			}
		},
		/** 签退 **/
		signBack({base64 = '', mediaId = ''}) {
			const _this = this
			this.$api.signOut(this.$route.query.signId, base64, mediaId,1).then((res) => {
				if (res.code === 0) {
					_this.$utils.dialog.alert('签退成功', () => {
						window.location.href = sessionStorage.getItem("loginSteam")
						window.location.reload()
					})
				}
			})
		},
		signInOrOut() {
			if (!this.signId) {
				// this.signIn();
				const _this = this;
				// this.signIn();
				this.loading = false;
				this.$dialog.alert({
					message: '签到照片为空，未避免影响学时，请前往首页重新签到进入',
					theme: 'round-button',
					confirmButtonColor: '#fca142',
					width: 290,
				}).then(() => {
					_this.$router.replace('/train')
				})
			} else {
				signOut(this.signId, this.avatar).then(() => {
					this.signId = '';
					this.showMask1 = false;
					this.showMask2 = false;
					this.showMask3 = false;
					this.showPop = false;
					this.showPop1 = false;
					this.loading = false;
					sessionStorage.removeItem('signId');
					this.player && this.player.destroy();
					// this.$destroy('videocomp');
					this.navBack();
				})
					.catch(() => {
						this.loading = false;
					})
			}
		},
		signIn() {
			this.loading = true;
			const info = this.getBrowserInfo();
			const type = (info[0].includes('iphone') || info[0].includes('ipad')) ? '苹果' : '安卓'
			signIn({
				studentId: this.userid,
				belongCategory: this.subject,
				unitType: type,
				signInIp: window.location.hostname,
			})
				.then((res) => {
					this.signId = res.basePkId;
					sessionStorage.setItem('signId', this.signId);
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
				})
		},
		upload(file) {
			if (this.avatar) return;

			const _file = file;
			const isLt2M = _file.size / 1024 / 1024 < 20;
			if (!isLt2M) {
				this.showAlert("请上传20M以下图片");
				return false;
			}

			// 通过 FormData 对象上传文件
			let formData = new FormData();
			formData.append("photo", _file);
			this.loading = true;
			compareFace(formData).then((data) => {
				this.avatar = data.url;
				this.signInOrOut();
			}).catch(() => {
				this.loading = false;
				this.$refs.imageInput.value = '';
				this.avatar = '';
			})
		},
		/** 签退不带照片 **/
		signBackNoPhoto() {
			const _this = this
			Dialog.confirm({
						title: '温馨提示',
						theme: 'round-button',
						message: '是否确认签退',
						cancelButtonColor: this.$store.state.app.theme.colorMain,
						confirmButtonColor: '#ee0a24',
						cancelButtonText:'确认',
						confirmButtonText:'取消',
						})
					.then(() => {

						})
					.catch(() => {
						this.$api.signOutNoPhoto(this.$route.query.signId,1).then((res) => {
							if (res.code === 0) {
								_this.$utils.dialog.alert('签退成功', () => {
									window.location.href = sessionStorage.getItem("loginSteam")
									window.location.reload()
								})
							}
						})
					});

		},
		signtip(index) {
			this.iscolor = index;
			if (index === 0) {
				this.showPopover = false;
			} else {
				this.showPopover = false;
				localStorage.setItem('showPopover', JSON.stringify(this.showPopover));
			}
		},
	}
}
</script>

<style scoped>
.header {
	z-index: 2;
	position: absolute;
	/* width: 100%; */
	/* height: 200px; */
	/* padding-bottom: 14px;
	border-bottom-left-radius: 26px 10px;
	border-bottom-right-radius: 26px 10px; */
	background: linear-gradient(90deg, #2db1ac, #2db1ac)
}
  .section-left {
	z-index: 2;
    position: absolute;
    display: flex;

	color:#ffffff;
    align-items: center;
  }
</style>

