<template>
	<div
		style="z-index:2000;"
		class="title"
		title-class="text-base"
	>
		<template v-if="$route.query.isFace !== 'noFace' && buttonType =='signOut' ">
				<van-button
				class="title_button back-line-gradient"
				@click="clickSignBack"
				:loading="loadingFace"
				style="z-index:2000; background-image: linear-gradient(to right, #ffb6b8, #f72e0c);text-shadow: 1px 2px #ba5852; text-align:right; float:right; width:120px;"
			>签&nbsp;退</van-button>
			<input v-show="false" ref="signBack" @change="imgChange($event)" type="file" accept="image/*" capture="user">
		</template>
		<template v-if="$route.query.isFace !== 'noFace' && buttonType =='reCheck'">
				<van-button
				class="title_button back-line-gradient"
				@click="clickSignBack"
				:loading="loadingFace"
				style="z-index:2000; background-image: linear-gradient(to right, #ffb6b8, #f72e0c);text-shadow: 1px 2px #ba5852; text-align:right; float:right; width:120px;"
			>重新验证</van-button>
			<input v-show="false" ref="signBack" @change="imgChange($event)" type="file" accept="image/*" capture="user">
		</template>
	</div>
</template>

<script>
export default {
	name: 'studyTitle',
	props: ['info', 'loadingFace','buttonType'],
	computed: {
		isUseJSPlayPhoto() {
			if (this.$store.state.cache.screen.wx) return true
			if (this.$route.query.state === 'js') return false
			if (this.$store.state.cache.single && this.$store.state.cache.single.state === 'single') return true
			return true
		}
	},
	methods: {
		clickSignBack () {
			this.handleSignOut();
			console.log("11111",this.buttonType)
		},
		handleSignOut() {
			const _this = this;
			this.$api.checkSignOut(this.$route.query.signId).then(res => {
				if (res.code === 0) {
					if (res.data.canBeSignOut == 1) {
						if (_this.loadingFace) return
						if (!_this.isUseJSPlayPhoto) {
							_this.$refs.signBack.click()
						} else {
							_this.$emit('handleFace')
						}
					} else {
						_this.$utils.dialog.alert(res.data.msg)
					}
				}
			})
		},
		imgChange (event) {
			if (this.loadingFace) return
			this.$emit('imgChange', event)
		}
	}
}
</script>

<style scoped>

</style>
